import React from "react";

import * as Icon from 'react-bootstrap-icons';
import LogoutUser from "../../pages/auth/LogoutUser";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar(props) {
    const location = useLocation();

    return (
        <>
            <div className="main-sidebar" id={"menu"}>
                <div id="menu-close" className="menu-close" onClick={props.handleMobileMenu}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 7L17 17M7 17L17 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"></path>
                        <defs>
                            <rect width="24" height="24" fill="currentColor"></rect>
                        </defs>
                    </svg>
                </div>
                <div className="menu">
                    <div className="group">
                        <span className="group-label">ZAKŁADKI</span>
                        <ul className="menu-group">
                            <li className={`menu-item ${location.pathname === '/marketplaces' ? 'active' : ''}`}>
                                <Link to="/marketplaces"  onClick={props.handleMobileMenu}>
                                    <Icon.CardList className={'icon'} size={20} />
                                    <span className="text">Linki</span>
                                </Link>
                            </li>
                            <li className={`menu-item ${location.pathname === '/scrapes' ? 'active' : ''}`}>
                                <Link to="/scrapes"  onClick={props.handleMobileMenu}>
                                    <Icon.CardList className={'icon'} size={20} />
                                    <span className="text">Zapytania</span>
                                </Link>
                            </li>
                            <li className={`menu-item ${location.pathname === '/stats' ? 'active' : ''}`}>
                                <Link to="/stats"  onClick={props.handleMobileMenu}>
                                    <Icon.CardList className={'icon'} size={20} />
                                    <span className="text">Statystyki</span>
                                </Link>
                            </li>
                            <li className={`menu-item ${location.pathname === '/ips' ? 'active' : ''}`}>
                                <Link to="/ips"  onClick={props.handleMobileMenu}>
                                    <Icon.CardList className={'icon'} size={20} />
                                    <span className="text">IP</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="group">
                        <span className="group-separator"></span>
                        <ul className="menu-group">
                            <li className="menu-item">
                                <LogoutUser />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}
