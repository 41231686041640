import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import _ from 'lodash';

import { toast } from 'react-toastify';

import 'react-loading-skeleton/dist/skeleton.css'

import { sendCreateIpRequest } from "../../../helpers/api-requests/ips";
import {Alert} from "react-daisyui";

export default function CreateIp() {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState('');

    const navigate = useNavigate();

    const tryCreateIp = async (event) => {
        const response = await sendCreateIpRequest(formData,() => {

        },() => {

        });

        if(response.status === 200) {
            if(response.message) {
                setErrors('');

                toast.success(response.message, {
                    hideProgressBar: true,
                });

                navigate("/ips", {
                    replace: true,
                });
            } else {
                setErrors({...response});
            }
        } else {
            setErrors({...response});
        }
    };

    const handleFormDataChange = (wireKey, value) => {
        let newFormData = {...formData};

        _.set(newFormData, wireKey, value);

        setFormData(newFormData);
    }

    return (
        <>
            <div className="main-section-content">
                <div className="main-section-title-wrapper">
                    <h1>Stwórz IP</h1>

                    <button className="button-primary" onClick={tryCreateIp}>
                        <div className="button-content">
                            <span>Zapisz</span>
                        </div>
                    </button>
                </div>

                <div className="columns-container">
                    <div className="left-column">
                        <div className="form">
                            <div className="form-card">
                                <div className="form-left-column">
                                    <div className="input">
                                        <label className="label" htmlFor="ip">IP</label>
                                        <div className="input-wrapper">
                                            <input type="text" id="ip" className="input-field  rounded-l rounded-r" placeholder="Podaj ip" onChange={({target: {value}}) => handleFormDataChange('ip', value)} />
                                        </div>
                                        { errors.errors?.ip && <Alert className={'text-sm py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors.ip[0] }</Alert> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}