import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import _ from 'lodash';

import { toast } from 'react-toastify';

import 'react-loading-skeleton/dist/skeleton.css'

import {
    sendFetchMarketplaceDataRequest,
    sendMarketplaceUpdateRequest
} from "../../../helpers/api-requests/marketplaces";
import { sendListEmailsRequest } from "../../../helpers/api-requests/emails";

import {Alert} from "react-daisyui";
import { MultiSelect } from "react-multi-select-component";

export default function EditMarketplace() {
    const { marketplace_id } = useParams();

    const [selectedEmails, setSelectedEmails] = useState([]);
    const [formData, setFormData] = useState({});

    const [errors, setErrors] = useState('');
    const [emails, setEmails] = useState([]);



    const fetchData = async (event) => {
        const response = await sendFetchMarketplaceDataRequest(marketplace_id, () => {
        },() => {
        });

        if(response.status === 200) {
            setFormData(response.data);

            await fetchEmails(response.data.emails);
        } else {
            setErrors({...response});
        }
    };

    const fetchEmails = async (currentEmails) => {
        const response = await sendListEmailsRequest();

        setEmails(response.data.data);
        setSelectedEmails(response.data.data.filter(email => currentEmails.find(currentEmail => currentEmail.id === email.id)).map(email => ({ label: email.email, value: email.id })));
    };

    const tryUpdate = async (event) => {
        const response = await sendMarketplaceUpdateRequest(marketplace_id, { ...formData, emails: selectedEmails.map(email => email.value) },() => {

        },() => {

        });

        if(response.status === 200) {
            if(response.message) {
                setErrors('');

                toast.success(response.message, {
                    hideProgressBar: true,
                });
            } else {
                setErrors({...response});
            }
        } else {
            setErrors({...response});
        }
    };

    const handleFormDataChange = (wireKey, value) => {
        let newFormData = {...formData};

        _.set(newFormData, wireKey, value);

        setFormData(newFormData);
    }

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <>
            <div className="main-section-content">
                <div className="main-section-title-wrapper">
                    <h1>Edytuj link</h1>

                    <button className="button-primary" onClick={tryUpdate}>
                        <div className="button-content">
                            <span>Zapisz</span>
                        </div>
                    </button>
                </div>

                <div className="columns-container">
                    <div className="left-column">
                        <div className="form">
                            <div className="form-card">
                                <div className="form-left-column">
                                    <div className="input">
                                        <label className="label" htmlFor="url">Link</label>
                                        <div className="input-wrapper">
                                            <input type="text" id="url" className="input-field  rounded-l rounded-r" placeholder="Podaj link" onChange={({target: {value}}) => handleFormDataChange('url', value)} value={formData.url} />
                                        </div>
                                        { errors.errors?.url && <Alert className={'text-sm py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors.url[0] }</Alert> }
                                    </div>
                                    <div className="input">
                                        <label className="label" htmlFor="description">Opis</label>
                                        <div className="input-wrapper">
                                            <textarea id="description" className="input-field  rounded-l rounded-r" placeholder="Podaj opis" onChange={({target: {value}}) => handleFormDataChange('description', value)} value={formData.description} />
                                        </div>
                                        { errors.errors?.description && <Alert className={'text-sm py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors.description[0] }</Alert> }
                                    </div>
                                    <div className="input">
                                        <label className="label" htmlFor="description">Opis</label>
                                        <div className="input-wrapper">
                                            {emails.length > 0 ? (
                                                <MultiSelect
                                                    options={emails.map(email => ({ label: email.email, value: email.id }))}
                                                    value={selectedEmails}
                                                    onChange={setSelectedEmails}
                                                    labelledBy="Select emails"
                                                />
                                            ) : (
                                                <p>Loading emails...</p>
                                            )}
                                        </div>
                                        { errors.errors?.description && <Alert className={'text-sm py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors.description[0] }</Alert> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}