import axiosInstance from "../../components/axiosInstance";

export const sendListIpsRequest = async (data, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.get('https://marketplace-api.swierczkacper.pl/api/v1/ips?' + new URLSearchParams(data).toString()).then(function (response) {
        callbackAfter();

        return response;
    }).catch(function (error) {
        callbackAfter();

        return error.response;
    });
}
export const sendRemoveIpRequest = async (id, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.delete('https://marketplace-api.swierczkacper.pl/api/v1/ips/' + id).then(function (response) {
        callbackAfter();

        return response;
    }).catch(function (error) {
        callbackAfter();

        return error.response;
    });
}

export const sendCreateIpRequest = async (data, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.post('https://marketplace-api.swierczkacper.pl/api/v1/ips', data).then(function (response) {
        callbackAfter();

        return response.data;
    }).catch(function (error) {
        callbackAfter();

        return error.response.data;
    });
}