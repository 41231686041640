import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import * as Icon from 'react-bootstrap-icons';

import { toast } from 'react-toastify';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Pagination from "../../partials/Pagination";
import Spinner from "../../partials/Spinner";

import { sendListIpsRequest, sendRemoveIpRequest } from "../../../helpers/api-requests/ips";

export default function IpsList() {
    const [responseData, setResponseData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [fetchingData, setFetchingData] = useState(false);
    const [removingIp, setRemovingIp] = useState(-1);

    const listIps = async (e, withoutFetch = false) => {
        const response = await sendListIpsRequest({
            'per_page': 10,
            'page': currentPage,
        }, () => {
            if(!withoutFetch) {
                setFetchingData(true);
            }
        },() => {
            setFetchingData(false);
        });


        if(response && response.status === 200) {
            setResponseData(response.data);

            if(currentPage > response?.data?.meta?.last_page) {
                setCurrentPage(response.data.meta.last_page);
            }
        }
    }

    const tryRemoveIp = async (event, ip_id) => {
        const response = await sendRemoveIpRequest(ip_id, () => {
            setRemovingIp(ip_id);
        },() => {
            setRemovingIp(-1);
        });

        if(response && response.status === 200) {
            toast.success(response.data.message, {
                hideProgressBar: true,
            });

            listIps(null, true);
        }
    };

    useEffect(() => {
        listIps();
    }, [currentPage])

    return (
        <>
            <div className="main-section-content">
                <div className="main-section-title-wrapper">
                    <h1>Lista IP</h1>

                    <Link to="/ips/create">
                        <button className="button-primary">
                            <div className="button-content">
                                <Icon.PlusSquare className={'icon'}/>
                                <span>Dodaj nowe ip</span>
                            </div>

                        </button>
                    </Link>
                </div>

                <div className={'table ips'}>
                    {
                        fetchingData ? (
                            <>
                                <div className={'table-header'}>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th actions'}><Skeleton className={'w-6/12'} height={20} /></div>
                                </div>
                                <div>
                                    {
                                        Array(10).fill().map((group, index) => {
                                            return (
                                                <div className={'tr'} key={index}>
                                                    <span className={'td'}><Skeleton className={'w-6/12'} height={20} /></span>
                                                    <span className={'td'}><Skeleton className={'w-6/12'} height={20} /></span>
                                                    <span className={'td actions'}><Skeleton className={'w-6/12'} height={20} /></span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={'table-header'}>
                                    <div className={'th'}>ID</div>
                                    <div className={'th'}>IP</div>
                                    <div className={'th'}>Status</div>
                                    <div className={'th actions'}>Akcje</div>
                                </div>
                                <div className={'table-body'}>
                                    {
                                        responseData.data?.map((ip, index) => {
                                            return (
                                                <div className={'tr'} key={index}>
                                                    <span className={'td'} title={ip.id}>
                                                        { ip.id.substring(0, 6) + (ip.id.length > 6 ? "..." : "") }
                                                    </span>
                                                    <span className={'td'}>{ ip.ip }</span>
                                                    <span className={'td'}>{ ip.active ? 'Aktywny' : 'Nieaktywny' }</span>
                                                    <span className={'td actions'}>
                                                        {/*<div className="icon-square bg-neutral-100">*/}
                                                        {/*    <Link to={`/ips/${ip.id}/edit`}>*/}
                                                        {/*        <Icon.Pencil className={'fill-primary-700'} size={20}/>*/}
                                                        {/*    </Link>*/}
                                                        {/*</div>*/}

                                                        <div onClick={event => tryRemoveIp(event, ip.id)} className="icon-square bg-neutral-100">
                                                            {
                                                                removingIp === ip.id ? (
                                                                    <Spinner/>
                                                                ) : (
                                                                    <>
                                                                        <Icon.Trash className={'fill-primary-700'} size={20}/>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        )
                    }
                </div>

                <Pagination currentPage={currentPage} lastPage={responseData?.meta?.last_page} links={responseData?.meta?.links} setCurrentPage={setCurrentPage}/>
            </div>
        </>
    );
}