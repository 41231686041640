import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Alert, Button, Input} from "react-daisyui";

import { sendLoginUserRequest } from "../../../helpers/api-requests/auth";
import Cookies from "universal-cookie";
import Spinner from "../../partials/Spinner";
import {toast} from "react-toastify";
import * as Icon from "react-bootstrap-icons";

export default function LoginPage(props) {
    const [emailInput, setEmailInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [errors, setErrors] = useState('');
    const [loading, setLoading] = useState('');

    const navigate = useNavigate();

    const tryLogin = async e => {
        e.preventDefault();

        const response = await sendLoginUserRequest({
            'email': emailInput,
            'password': passwordInput,
        }, () => {
            setLoading(true);
        },() => {
            setLoading(false);
        });

        if(response.status === 200) {
            if(response.data) {
                setErrors('');

                new Cookies().set('session', response.data.token, { path: '/' });

                navigate("/marketplaces", {
                    replace: true,
                });
            } else {
                setErrors({...response.data});
            }
        } else {
            setErrors({...response.data});
        }
    }

    return (
        <>
            <div className="auth-container">
                <div className="left-column">
                    <form className="form" action={''} onSubmit={tryLogin}>
                        <p className="auth-title">Witaj ponownie!</p>
                        <div className="input">
                            <label className="label" htmlFor="email">E-mail</label>
                            <div className="input-wrapper">
                                <input type={'email'} className="input-field  rounded-l rounded-r" placeholder={'Podaj e-mail'} value={emailInput} onChange={(event) => setEmailInput(event.target.value)} disabled={loading}/>
                            </div>
                            { errors.errors?.email && <Alert className={'text-sm py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors.email[0] }</Alert> }
                        </div>
                        <div className="input">
                            <label className="label" htmlFor="password">Hasło</label>
                            <div className="input-wrapper">
                                <input type={'password'} className="input-field  rounded-l rounded-r" placeholder={'Podaj hasło'} value={passwordInput} onChange={(event) => setPasswordInput(event.target.value)} disabled={loading}/>
                            </div>
                            { errors.errors?.email && <Alert className={'text-sm py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.errors.email[0] }</Alert> }
                        </div>
                        <Link to={'/forgot-password'} className="forgot-password-link">Zapomniałeś hasła?</Link>
                        <button className="button-primary">
                            <div className="button-content">
                                <span>{ loading ? <Spinner/> : 'Zaloguj' }</span>
                            </div>
                        </button>

                        {
                            (errors && (errors.status === undefined || (errors.status !== 400))) && <Alert className={'text-sm py-2 px-4 mt-2 rounded-lg'} status={'error'}>{ errors.message }</Alert>
                        }
                    </form>
                </div>
                <div className="right-column">

                </div>
            </div>
        </>
    );
}