import axiosInstance from "../../components/axiosInstance";

export const sendListMarketplacesRequest = async (data, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.get('https://marketplace-api.swierczkacper.pl/api/v1/marketplaces?' + new URLSearchParams(data).toString()).then(function (response) {
        callbackAfter();

        return response;
    }).catch(function (error) {
        callbackAfter();

        return error.response;
    });
}

export const sendCreateMarketplaceRequest = async (data, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.post('https://marketplace-api.swierczkacper.pl/api/v1/marketplaces', data).then(function (response) {
        callbackAfter();

        return response.data;
    }).catch(function (error) {
        callbackAfter();

        return error.response.data;
    });
}

export const sendFetchMarketplaceDataRequest = async (id, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.get('https://marketplace-api.swierczkacper.pl/api/v1/marketplaces/' + id).then(function (response) {
        callbackAfter();

        return response.data;
    }).catch(function (error) {
        callbackAfter();

        return error.response.data;
    });
}

export const sendMarketplaceUpdateRequest = async (id, data, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.put('https://marketplace-api.swierczkacper.pl/api/v1/marketplaces/' + id, data).then(function (response) {
        callbackAfter();

        return response.data;
    }).catch(function (error) {
        callbackAfter();

        return error.response.data;
    });
}

export const sendRemoveMarketplaceRequest = async (id, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.delete('https://marketplace-api.swierczkacper.pl/api/v1/marketplaces/' + id).then(function (response) {
        callbackAfter();

        return response;
    }).catch(function (error) {
        callbackAfter();

        return error.response;
    });
}
