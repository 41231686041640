import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import * as Icon from 'react-bootstrap-icons';

import { toast } from 'react-toastify';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Pagination from "../../partials/Pagination";
import Spinner from "../../partials/Spinner";

import { sendListStatsRequest } from "../../../helpers/api-requests/stats";

export default function StatsList() {
    const [responseData, setResponseData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [fetchingData, setFetchingData] = useState(false);

    const listGroups = async (e, withoutFetch = false) => {
        const response = await sendListStatsRequest({
            'per_page': 100,
            'page': currentPage,
        }, () => {
            if(!withoutFetch) {
                setFetchingData(true);
            }
        },() => {
            setFetchingData(false);
        });

        if(response && response.status === 200) {
            setResponseData(response.data);

            if(currentPage > response?.data?.meta?.last_page) {
                setCurrentPage(response.data.meta.last_page);
            }
        }
    }

    useEffect(() => {
        listGroups();
    }, [currentPage])

    return (
        <>
            <div className="main-section-content">
                <div className="main-section-title-wrapper">
                    <h1>Lista iteracji programu</h1>
                </div>

                <div className={'table stats'}>
                    {
                        fetchingData ? (
                            <>
                                <div className={'table-header'}>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={'table-header'}>
                                    <div className={'th'}>ID</div>
                                    <div className={'th'}>Pobranie IP</div>
                                    <div className={'th'}>Pobranie linków</div>
                                    <div className={'th'}>Pobranie emaili</div>
                                    <div className={'th'}>Pobranie zawartości linków</div>
                                    <div className={'th'}>Zeskanowanie linków</div>
                                    <div className={'th'}>Odfiltrowanie ofert</div>
                                    <div className={'th'}>Zapis</div>
                                    <div className={'th'}>Wysyłka emaili</div>
                                    <div className={'th'}>Łącznie</div>
                                    <div className={'th'}>Linki</div>
                                    <div className={'th'}>Ilość ofert</div>
                                    <div className={'th'}>Nowe oferty</div>
                                    <div className={'th'}>Data</div>
                                </div>
                                <div className={'table-body'}>
                                    {
                                        responseData.data?.map((stat, index) => {
                                            return (
                                                <div className={'tr'} key={index}>
                                                    <span className={'td'}>{ stat.id.substring(0, 6) + (stat.id.length > 6 ? "..." : "") }</span>
                                                    <span className={'td'}>{ stat.time_to_fetch_ip_from_database !== null ? stat.time_to_fetch_ip_from_database.toFixed(2) + 's' : 'Brak' }</span>
                                                    <span className={'td'}>{ stat.time_to_fetch_urls_from_database !== null ? stat.time_to_fetch_urls_from_database.toFixed(2) + 's' : 'Brak' }</span>
                                                    <span className={'td'}>{ stat.time_to_fetch_emails_from_database !== null ? stat.time_to_fetch_emails_from_database.toFixed(2) + 's' : 'Brak' }</span>
                                                    <span className={'td'}>{ stat.time_to_download_html !== null ? stat.time_to_download_html.toFixed(2) + 's' : 'Brak' }</span>
                                                    <span className={'td'}>{ stat.time_to_parse_html !== null ? stat.time_to_parse_html.toFixed(2) + 's' : 'Brak' }</span>
                                                    <span className={'td'}>{ stat.time_to_filter_offers !== null ? stat.time_to_filter_offers.toFixed(2) + 's' : 'Brak' }</span>
                                                    <span className={'td'}>{ stat.time_to_save_to_database !== null ? stat.time_to_save_to_database.toFixed(2) + 's' : 'Brak' }</span>
                                                    <span className={'td'}>{ stat.time_to_send_emails !== null ? stat.time_to_send_emails.toFixed(2) + 's' : 'Brak' }</span>
                                                    <span className={'td'}>{ stat.time_final !== null ? stat.time_final.toFixed(2) + 's' : 'Brak' }</span>
                                                    <span className={'td'}>{ stat.stat_urls_scraped !== null ? stat.stat_urls_scraped : 'Brak' }</span>
                                                    <span className={'td'}>{ stat.stat_founded_offers !== null ? stat.stat_founded_offers : 'Brak' }</span>
                                                    <span className={'td'}>{ stat.stat_founded_new_offers !== null ? stat.stat_founded_new_offers : 'Brak' }</span>
                                                    <span className={'td'}>{ stat.created_at }</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        )
                    }
                </div>

                <Pagination currentPage={currentPage} lastPage={responseData?.meta?.last_page} links={responseData?.meta?.links} setCurrentPage={setCurrentPage}/>
            </div>
        </>
    );
}