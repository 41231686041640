import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import * as Icon from 'react-bootstrap-icons';

import { toast } from 'react-toastify';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement
} from "chart.js";
import {Line} from "react-chartjs-2";

import {sendListScrapesPerDaysRequest, sendListScrapesRequest} from "../../../helpers/api-requests/scrapes";

export default function Stats() {
    const [responseData, setResponseData] = useState([]);

    const [fetchingData, setFetchingData] = useState(false);
    const [daysFilter, setDaysFilter] = useState(7);
    const [successFilter, setSuccessFilter] = useState("");
    const [chartData, setChartData] = useState([]);

    ChartJS.register(ArcElement, Tooltip, Legend);
    ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

    const fetchStats = async (e, withoutFetch = false) => {
        const response = await sendListScrapesPerDaysRequest({
            'days': daysFilter,
            ...(successFilter !== "" ? {'success': successFilter} : {})
        }, () => {
            if(!withoutFetch) {
                setFetchingData(true);
            }
        },() => {
            setFetchingData(false);
        });

        if(response && response.status === 200) {
            setResponseData(response.data);
        }
    }

    const prepareChartData = () => {
        if(responseData.data) {
            const labels = responseData.data.map(item => item.date).reverse();
            const data = responseData.data.map(item => item.count).reverse();

            setChartData({
                labels: labels,
                datasets: [
                    {
                        label: 'Ilość zapytań',
                        data: data,
                        fill: false,
                        borderColor: 'rgb(75, 192, 192)',
                        tension: 0.1
                    }
                ]
            });
        }
    }

    useEffect(() => {
        fetchStats();
    }, [daysFilter, successFilter])

    useEffect(() => {
        prepareChartData();
    }, [responseData])

    return (
        <>
            <div className="main-section-content">
                <div className="main-section-title-wrapper">
                    <h1>Statystyki zapytań</h1>

                    <div className={'filters flex gap-x-3'}>
                        <select
                            value={daysFilter}
                            onChange={(e) => setDaysFilter(Number(e.target.value))}
                        >
                            <option value={7}>7 dni</option>
                            <option value={14}>14 dni</option>
                            <option value={30}>30 dni</option>
                        </select>

                        <select
                            value={successFilter}
                            onChange={(e) => setSuccessFilter(e.target.value === "true")}
                        >
                            <option value="">Wszystkie statusy</option>
                            <option value="true">Tylko sukcesy</option>
                            <option value="false">Tylko niepowodzenia</option>
                        </select>
                    </div>
                </div>
                <div className="stats">
                    {
                        fetchingData ? (
                            <>Wczytywanie danych...</>
                        ) : (
                            chartData.labels ? <Line data={chartData} /> : 'Brak danych do wyświetlenia'
                        )
                    }
                </div>
            </div>
        </>
    );
}