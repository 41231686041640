import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const instance = axios.create({
    baseURL: 'https://marketplace-api.swierczkacper.pl/api/v1'
});

instance.interceptors.request.use(function (config) {
    if(new Cookies().get('session')) {
        config.headers.Authorization = `Bearer ${new Cookies().get('session')}`;
    }

    return config;
}, function (error) {
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    return response;
}, error => {
    let firstKey = '';

    // if(error.response.data && error.response.data.errors) {
    //     const validationErrors = error.response.data.errors;
    //     firstKey = Object.keys(validationErrors)[0];
    // }

    toast.error((
        <>
            <div className={'text-sm'}>{ error.response.data.message }</div>

            {/*{ error.response?.data?.errors && <p className={'text-xs'}>{ firstKey }: { error.response.data.errors[firstKey][0] }</p> }*/}
        </>
    ), {
        hideProgressBar: true,
    });

    if(error.response.status === 401) {
        new Cookies().remove('session');

        localStorage.removeItem('user');

        if(error.response.message === "Unauthenticated.") {
            const navigate = useNavigate();

            toast.error((
                <>
                    <div className={'text-sm'}>Sesja wygasła</div>
                    <p className={'text-xs'}>Zaloguj się ponownie.</p>
                </>
            ), {
                hideProgressBar: true,
            });

            navigate("/login", {
                replace: true,
            });
        }
    }

    return Promise.reject(error);
});

export default instance;