import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import * as Icon from 'react-bootstrap-icons';

import { toast } from 'react-toastify';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Pagination from "../../partials/Pagination";
import Spinner from "../../partials/Spinner";

import { sendListScrapesRequest } from "../../../helpers/api-requests/scrapes";

export default function ScrapesList() {
    const [responseData, setResponseData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [fetchingData, setFetchingData] = useState(false);

    const listGroups = async (e, withoutFetch = false) => {
        const response = await sendListScrapesRequest({
            'per_page': 100,
            'page': currentPage,
        }, () => {
            if(!withoutFetch) {
                setFetchingData(true);
            }
        },() => {
            setFetchingData(false);
        });

        if(response && response.status === 200) {
            setResponseData(response.data);

            if(currentPage > response?.data?.meta?.last_page) {
                setCurrentPage(response.data.meta.last_page);
            }
        }
    }

    useEffect(() => {
        listGroups();
    }, [currentPage])

    return (
        <>
            <div className="main-section-content">
                <div className="main-section-title-wrapper">
                    <h1>Lista zapytań</h1>

                    <Link to="/scrapes/stats">
                        <button className="button-primary">
                            <div className="button-content">
                                <Icon.BarChart className={'icon'}/>
                                <span>Pokaż statystyki</span>
                            </div>

                        </button>
                    </Link>
                </div>

                <div className={'table scrapes'}>
                    {
                        fetchingData ? (
                            <>
                                <div className={'table-header'}>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={'table-header'}>
                                    <div className={'th'}>ID</div>
                                    <div className={'th'}>Link</div>
                                    <div className={'th'}>IP</div>
                                    <div className={'th'}>Status</div>
                                    <div className={'th'}>Błąd</div>
                                    <div className={'th'}>Data</div>
                                </div>
                                <div className={'table-body'}>
                                    {
                                        responseData.data?.map((scrape, index) => {
                                            return (
                                                <div className={'tr'} key={index}>
                                                    <span className={'td'}>{ scrape.id.substring(0, 6) + (scrape.id.length > 6 ? "..." : "") }</span>
                                                    <span className={'td break-all hover:!text-primary-600'}>
                                                        <a href={scrape.marketplace.url} target="_blank" rel="noopener noreferrer">
                                                            { scrape.marketplace.url.substring(0, 30) + (scrape.marketplace.url.length > 30 ? "..." : "") }
                                                        </a>
                                                    </span>
                                                    <span className={'td'}>{ scrape.ip.ip }</span>
                                                    <span className={'td'}>{ scrape.success ? 'Udany' : 'Nieudany' }</span>
                                                    <span className={'td'}>{ scrape.error ? scrape.error : 'Brak' }</span>
                                                    <span className={'td'}>{ scrape.created_at }</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        )
                    }
                </div>

                <Pagination currentPage={currentPage} lastPage={responseData?.meta?.last_page} links={responseData?.meta?.links} setCurrentPage={setCurrentPage}/>
            </div>
        </>
    );
}