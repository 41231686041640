import { Outlet } from "react-router-dom";
import React, {useEffect, useState} from "react";
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import 'react-nestable/dist/styles/index.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../../assets/scss/styles.scss';
import Sidebar from "../partials/Sidebar/Sidebar";

export default function AppProtected() {
    const navigate = useNavigate();
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

    useEffect(() => {
        if(!new Cookies().get('session'))
        {
            navigate("/login", {
                replace: true,
            });
        }
    });

    const handleMobileMenu = () => {
        const menu = document.getElementById('menu');

        if(!mobileMenuOpened) {
            menu.classList.add('active');
            setMobileMenuOpened(true);
        } else {
            menu.classList.remove('active');
            setMobileMenuOpened(false);
        }
    }

    return (
        <>
            <Sidebar handleMobileMenu={handleMobileMenu} />

            <main className="main-content fill-primary-600">
                <header className="main-header">
                    <div className="buttons">

                    </div>
                    <div className="right-content">
                        <div className={"hamburger"} id={"menu-toggle"} onClick={handleMobileMenu}>
                            <svg className="fill-current text-gray-900"
                                 xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <title>menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                            </svg>
                        </div>
                    </div>
                </header>


                <section className="main-section">
                    <SkeletonTheme baseColor="#ebebeb" highlightColor="#f5f5f5">
                        <Outlet />
                        <ToastContainer />
                    </SkeletonTheme>
                </section>
            </main>
        </>
    )
};