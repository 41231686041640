import axiosInstance from "../../components/axiosInstance";

export const sendLoginUserRequest = async (data, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.post('https://marketplace-api.swierczkacper.pl/api/v1/auth/login', data).then(function (response) {
        callbackAfter();

        return response;
    }).catch(function (error) {
        callbackAfter();

        return error.response;
    });
}

export const sendLogoutUserRequest = async (data, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.post('https://marketplace-api.swierczkacper.pl/api/v1/auth/logout', data).then(function (response) {
        callbackAfter();

        return response;
    }).catch(function (error) {
        callbackAfter();

        return error.response;
    });
}