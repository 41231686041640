import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import * as Icon from 'react-bootstrap-icons';

import { toast } from 'react-toastify';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Pagination from "../../partials/Pagination";
import Spinner from "../../partials/Spinner";

import { sendListMarketplacesRequest, sendRemoveMarketplaceRequest } from "../../../helpers/api-requests/marketplaces";

export default function MarketplacesList() {
    const [responseData, setResponseData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [fetchingData, setFetchingData] = useState(false);
    const [removingMarketplace, setRemovingMarketplace] = useState(-1);

    const listGroups = async (e, withoutFetch = false) => {
        const response = await sendListMarketplacesRequest({
            'per_page': 10,
            'page': currentPage,
        }, () => {
            if(!withoutFetch) {
                setFetchingData(true);
            }
        },() => {
            setFetchingData(false);
        });


        if(response && response.status === 200) {
            setResponseData(response.data);

            if(currentPage > response?.data?.meta?.last_page) {
                setCurrentPage(response.data.meta.last_page);
            }
        }
    }

    const tryRemoveMarketplace = async (event, marketplace_id) => {
        const response = await sendRemoveMarketplaceRequest(marketplace_id, () => {
            setRemovingMarketplace(marketplace_id);
        },() => {
            setRemovingMarketplace(-1);
        });

        if(response && response.status === 200) {
            toast.success(response.data.message, {
                hideProgressBar: true,
            });

            listGroups(null, true);
        }
    };

    useEffect(() => {
        listGroups();
    }, [currentPage])

    return (
        <>
            <div className="main-section-content">
                <div className="main-section-title-wrapper">
                    <h1>Lista linków</h1>

                    <Link to="/marketplaces/create">
                        <button className="button-primary">
                            <div className="button-content">
                                <Icon.PlusSquare className={'icon'}/>
                                <span>Dodaj nowy link</span>
                            </div>

                        </button>
                    </Link>
                </div>

                <div className={'table marketplaces'}>
                    {
                        fetchingData ? (
                            <>
                                <div className={'table-header'}>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th'}><Skeleton className={'w-6/12'} height={20} /></div>
                                    <div className={'th actions'}><Skeleton className={'w-6/12'} height={20} /></div>
                                </div>
                                <div>
                                    {
                                        Array(10).fill().map((group, index) => {
                                            return (
                                                <div className={'tr'} key={index}>
                                                    <span className={'td'}><Skeleton className={'w-6/12'} height={20} /></span>
                                                    <span className={'td'}><Skeleton className={'w-6/12'} height={20} /></span>
                                                    <span className={'td'}><Skeleton className={'w-6/12'} height={20} /></span>
                                                    <span className={'td actions'}><Skeleton className={'w-6/12'} height={20} /></span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={'table-header'}>
                                    <div className={'th'}>ID</div>
                                    <div className={'th'}>Link</div>
                                    <div className={'th'}>Opis</div>
                                    <div className={'th'}>Status</div>
                                    <div className={'th actions'}>Akcje</div>
                                </div>
                                <div className={'table-body'}>
                                    {
                                        responseData.data?.map((marketplace, index) => {
                                            return (
                                                <div className={'tr'} key={index}>
                                                    <span className={'td'}>{ marketplace.id }</span>
                                                    <span className={'td break-all hover:!text-primary-600'}>
                                                        <a href={marketplace.url} target="_blank" rel="noopener noreferrer">
                                                            { marketplace.url.substring(0, 60) + (marketplace.url.length > 60 ? "..." : "") }
                                                        </a>
                                                    </span>
                                                    <span className={'td'}>{ marketplace.description }</span>
                                                    <span className={'td'}>{ marketplace.active ? 'Aktywny' : 'Nieaktywny' }</span>
                                                    <span className={'td actions'}>
                                                        <div className="icon-square bg-neutral-100">
                                                            <Link to={`/marketplaces/${marketplace.id}/edit`}>
                                                                <Icon.Pencil className={'fill-primary-700'} size={20}/>
                                                            </Link>
                                                        </div>

                                                        <div onClick={event => tryRemoveMarketplace(event, marketplace.id)} className="icon-square bg-neutral-100">
                                                            {
                                                                removingMarketplace === marketplace.id ? (
                                                                    <Spinner/>
                                                                ) : (
                                                                    <>
                                                                        <Icon.Trash className={'fill-primary-700'} size={20}/>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </>
                        )
                    }
                </div>

                <Pagination currentPage={currentPage} lastPage={responseData?.meta?.last_page} links={responseData?.meta?.links} setCurrentPage={setCurrentPage}/>
            </div>
        </>
    );
}