import axiosInstance from "../../components/axiosInstance";

export const sendListStatsRequest = async (data, callbackBefore = () => {}, callbackAfter = () => {}) => {
    callbackBefore();

    return await axiosInstance.get('https://marketplace-api.swierczkacper.pl/api/v1/stats?' + new URLSearchParams(data).toString()).then(function (response) {
        callbackAfter();

        return response;
    }).catch(function (error) {
        callbackAfter();

        return error.response;
    });
}