import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { QueryClient, QueryClientProvider} from "@tanstack/react-query";

import reportWebVitals from './reportWebVitals';

import App from "./components/layouts/App";
import AppProtected from "./components/layouts/AppProtected";

import MarketplacesList from "./components/pages/marketplaces/MarketplacesList";
import CreateMarketplace from "./components/pages/marketplaces/CreateMarketplace";
import IpsList from "./components/pages/ips/IpsList";
import CreateIp from "./components/pages/ips/CreateIp";
import ScrapesList from "./components/pages/scrapes/ScrapesList";
import Stats from "./components/pages/scrapes/Stats";
import StatsList from "./components/pages/stats/StatsList";

import LoginPage from "./components/pages/auth/LoginPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import EditMarketplace from "./components/pages/marketplaces/EditMarketplace";

const root = ReactDOM.createRoot(document.getElementById('root'));

const routes = [
    { path: "/", Element: Navigate, redirectTo: "/marketplaces", exact: true },
    { path: "/login", Element: LoginPage, exact: true },
];

const routesProtected = [
    { path: "/marketplaces", Element: MarketplacesList, exact: true },
    { path: "/marketplaces/create", Element: CreateMarketplace, exact: true },
    { path: "/marketplaces/:marketplace_id/edit", Element: EditMarketplace, exact: true },
    { path: "/ips", Element: IpsList, exact: true },
    { path: "/ips/create", Element: CreateIp, exact: true },
    { path: "/scrapes", Element: ScrapesList, exact: true },
    { path: "/scrapes/stats", Element: Stats, exact: true },
    { path: "/stats", Element: StatsList, exact: true },
];

const queryClient = new QueryClient()

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Routes>
                    <Route key="*" path="*" element={<NotFoundPage />}/>
                    <Route element={<App />}>
                        {
                            routes.map(({ path, Element, redirectTo, exact }) => (
                                <Route
                                    key={path}
                                    path={path}
                                    element={redirectTo ? <Element to={redirectTo} /> : <Element />}
                                    exact={exact}
                                />
                            ))
                        }
                    </Route>
                    <Route element={<AppProtected />}>
                        {
                            routesProtected.map(({ path, Element, exact }) => (
                                <Route key={path} path={path} element={<Element />} exact={exact}/>
                            ))
                        }
                    </Route>
                </Routes>
            </BrowserRouter>
        </QueryClientProvider>
    </React.StrictMode>
);

reportWebVitals();
